input[type='text']:read-only,
input[type='text']:disabled,
input[type='text'][aria-disabled='true'],
input[type='search']:read-only,
input[type='search']:disabled,
input[type='search'][aria-disabled='true'],
input[type='number']:read-only,
input[type='number']:disabled,
input[type='number'][aria-disabled='true'],
select.read-only,
textarea:read-only,
textarea:disabled,
textarea[aria-disabled='true'] {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.input-card {
  &--v-spacing {
    margin-top: 23px;
  }
}

.input {
  display: block;

  &.input--search {
    padding-right: 25px;
  }

  &.input--currency {
    padding-left: 20px;
  }

  &.input--spin-button:not(:read-only) {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: inner-spin-button;
      opacity: 1;
      height: 25px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &[type='search'] {
    appearance: none;
  }

  /* clears the ‘X’ from Internet Explorer */
  &[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the ‘X’ from Chrome */
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.input.error {
  border-color: #e02020;
}

.input-card label {
  display: block;
  font-size: 16px;
  color: #193e72;
}

.input-card p {
  margin-bottom: 8px;
  color: #606dab;
}
.input-card a {
  color: #606dab;
}

.right-icon {
  position: absolute;
  top: 8px;
  right: 2px;
  pointer-events: none;

  svg {
    fill: var(--charcoal);
  }
}

.has-centre-icon {
  width: 50%;
}

.currency {
  position: absolute;
  top: 9px;
  left: 8px;
  pointer-events: none;
}
