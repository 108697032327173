.drop-down {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.drop-down.error {
  border-color: #e02020;
}

.drop-down.arrows {
  background-image: url("data:image/svg+xml,%3Csvg width='8px' height='19px' viewBox='0 0 8 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup 4%3C/title%3E%3Cg id='Triage' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Study-representative_ETC-value-letter' transform='translate(-502.000000, -453.000000)' fill='%23000000'%3E%3Cg id='form-/-radio-off' transform='translate(260.000000, 271.000000)'%3E%3Cg id='Group-4' transform='translate(242.000000, 182.000000)'%3E%3Cpolygon id='Triangle' points='4 0 8 8 0 8'%3E%3C/polygon%3E%3Cpolygon id='Triangle' transform='translate(4.000000, 15.000000) rotate(180.000000) translate(-4.000000, -15.000000) ' points='4 11 8 19 0 19'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: calc(100% - 0.3rem), 100% 0;
  background-size: 0.5rem 1rem;
  background-repeat: no-repeat;
  padding-right: 15px;
}

.drop-down-card {
  display: block;
  position: relative;
  margin-top: 23px;
}
.drop-down-card label {
  display: block;
  font-size: 16px;
  color: #193e72;
}
