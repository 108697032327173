[data-reach-menu-popover] {
  margin-top: 6px;
}

[data-reach-menu-link][data-selected] {
  background: transparent;
  color: inherit;
  text-decoration: underline;
}

[data-reach-menu-link][data-selected] {
  background: transparent;
  color: inherit;
  text-decoration: underline;
}

.action-arrow {
  font-size: 6px;
}