.questionText {
  display: block;
  color: #193e72;
}

.guidanceText {
  display: block;
  color: #606dab;
}

.bottomMargin {
  margin-bottom: 10px;
}

.summaryTable tbody tr:nth-child(odd) {
  background-color: #e6e6e6;
}

.summaryTable tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

.summaryTable {
  width: 100%;
  border: none;
}

th {
  text-align: left;
  font-weight: normal;
  font-size: 15px;
  padding-bottom: 5px;
}

td {
  width: 50%;
  padding: 10px;
}

/* tfoot {
    background-color:#fff9c4;
    display: block;
    margin-top: 2%;
    width: 95%;
} */

/* .footerTd {
    margin-left: 10px;
    width: 95%;
}

.footerDiv {
    padding-left: 10px;
} */

.buttonContainer {
  padding-top: 20px;
}
