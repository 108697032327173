.radios-container {
  display: block;
  position: relative;
  background-color: #fff;
  margin: 10px 0 0;
}

.radios-container p {
  color: var(--cobalt);
}
.radios-container span {
  font-size: 16px;
  color: #747ca3;
}

.radio-card {
  margin: 1rem 6px 0;
  display: block;
}

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:focus,
input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.1);
  box-shadow: 0px 0px 0px 3px white inset;
}

input[type="radio"]:checked + label span {
  background-color: #193e72;
  box-shadow: 0px 0px 0px 3px white inset;
}

input[type="radio"]:checked + label:not(.nhsuk-radios__label--secondary) {
  color: #193e72;
}

.oval {
  width: 35px;
  height: 35px;
  margin: 0 9px 0 0;
  border: solid 2px #4c6272;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
}
.oval.error {
  border-color: #e02020;
}

.radio-card label {
  &:not(.nhsuk-radios__label--secondary) {
    color: #193e72;
    margin: 10px 0;
  }

  display: flex;
  align-items: center;
  min-width: 36px;
}

.radio-card .description {
  margin: -12px 0 0 12px;
  color: #606dab;
}

.nhsuk-radios__item {
  .nhsuk-radios__label--secondary {
    color: white;
  }
}
