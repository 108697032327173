@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap");
@import "./assets/icons/icons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    position: relative;
    @apply bg-body font-base min-h-screen;
  }
  p {
    @apply text-base;
  }
  label, .nhsuk-label {
    @apply text-blue text-base font-base;
  }
  .nhsuk-radios__input--error + .nhsuk-radios__label::before {
    @apply border-red;
  }
  .nhsuk-button {
    @apply bg-green-dark;
  }
  .nhsuk-button--secondary {
    @apply bg-blue;
  }
  .nhsuk-button--reverse {
    @apply bg-white;
  }
}

@layer utilities {
  .geometric-precision {
    text-rendering: geometricPrecision;
  }
}

*[class^="icon-"],
*[class*="icon-"] {
  line-height: 0;
}
