.loader-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}
.loader {
  position: relative;
  border: 2px solid var(--cobalt);
  border-left: 2px solid var(--green);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.loader-message {
  margin-left: 5px;
  color: var(--cobalt);
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
