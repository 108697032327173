.hamburger {
  .hamburger-box {
    width: 18px;
    height: 18px;
  }
  .hamburger-inner {
    &:before { top: -5px; }
    &:after { bottom: -5px; }
    &:before, &:after {
      display: block;
      content: "";
    }
  }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    position: absolute;
    width: 18px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 3px;
    background-color: #fff;
  }
  &.active {
    .hamburger-inner {
      &:before {
        transform: translate3d(-5px,1px,0) rotate(-45deg) scaleX(.7);
      }

      &:after {
        transform: translate3d(-5px,-1px,0) rotate(45deg) scaleX(.7);
      }
    }
  }
}
