input[type="checkbox"] {
  transition: background 0.2s, transform 0.2s;
}

input[type="checkbox"] {
  transform: scale(1.2);
}

input[type="checkbox"]:checked {
  background-color: #193e72;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="checkbox"]:checked {
  color: #193e72;
}

.scrollBar {
  max-height: 250px;
  overflow-y: auto;
  margin: 10px auto;
  padding: 20px;
  margin-bottom: 20px;
}
.scrollBar p {
  padding: 10px 0;
}

.checkbox {
  width: 35px;
  height: 35px;
  margin: 0 9px 0 0;
  border: solid 2px #4c6272;
  background-color: #fff;
}
.checkbox.error {
  border-color: #e02020;
}
