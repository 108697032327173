:root {
  --nav-width-collapsed: 50px;
  --nav-width-expanded: 340px;
}

.navigation {
  top: var(--header-height);

  &:not(.navigation--expanded) {
    width: var(--nav-width-collapsed); 
  }

  &--expanded {
    width: var(--nav-width-expanded); 
  }

  .icon {
    width: 20px;
  }

  + .nhsuk-width-container {
    padding-left: var(--nav-width-collapsed); // Add spacing to offset the width of the collapsed vertical nav
  }
}

.navigation-link {
  color: var(--dark-gray);
  width: var(--nav-width-expanded);

  .navigation--expanded & {
    width: calc(var(--nav-width-expanded) - (0.75rem * 2));
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover, &:visited {
    color: var(--dark-gray);
  }

  &--active {
    color: var(--cobalt);

    &:hover, &:visited {
      color: var(--cobalt);
    }
  }
}

.navigation-link-text {
  .navigation--expanded & {
    opacity: 1;
    transform: translateX(0);
  }
}
