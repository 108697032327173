@import 'nhsuk-frontend-backports.scss'; //

@import './vendor/nhs/core/all.scss'; // NHS Core styles & library

@import '~nhsuk-frontend/packages/components/header/header.scss';
@import '~nhsuk-frontend/packages/components/button/button.scss';
@import '~nhsuk-frontend/packages/components/checkboxes/checkboxes.scss';
@import '~nhsuk-frontend/packages/components/radios/radios.scss';
@import '~nhsuk-frontend/packages/components/textarea/textarea.scss';
@import '~nhsuk-frontend/packages/components/fieldset/fieldset.scss';
@import '~nhsuk-frontend/packages/components/label/label.scss';
@import '~nhsuk-frontend/packages/components/hint/hint.scss';
@import '~nhsuk-frontend/packages/components/input/input.scss';
@import '~nhsuk-frontend/packages/components/select/select.scss';
@import '~nhsuk-frontend/packages/components/skip-link/skip-link.scss';
@import '~nhsuk-frontend/packages/components/pagination/pagination.scss';

@import 'overrides';

:root {
  // Misc
  --transition: width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), height 0.5s ease;

  // @reach
  --reach-disclosure: 1;
  --reach-tabs: 1;
}


