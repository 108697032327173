@font-face {
    font-family: "icons";
    src: url("./icons.eot?d8eefd9cb59c9bec966f4a6afd68e266#iefix") format("embedded-opentype"),
url("./icons.woff2?d8eefd9cb59c9bec966f4a6afd68e266") format("woff2"),
url("./icons.woff?d8eefd9cb59c9bec966f4a6afd68e266") format("woff");
}

span[class^="icon-"]:before, span[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-action-flag:before {
    content: "\f101";
}
.icon-arrow-down:before {
    content: "\f102";
}
.icon-arrow-left:before {
    content: "\f103";
}
.icon-arrow-right:before {
    content: "\f104";
}
.icon-arrow-up:before {
    content: "\f105";
}
.icon-award-attached:before {
    content: "\f106";
}
.icon-chevron-down:before {
    content: "\f107";
}
.icon-chevron-up:before {
    content: "\f108";
}
.icon-close:before {
    content: "\f109";
}
.icon-cog:before {
    content: "\f10a";
}
.icon-comment:before {
    content: "\f10b";
}
.icon-commerical-studies:before {
    content: "\f10c";
}
.icon-commisioner-request:before {
    content: "\f10d";
}
.icon-confirm-research-activity:before {
    content: "\f10e";
}
.icon-copy:before {
    content: "\f10f";
}
.icon-download:before {
    content: "\f110";
}
.icon-draft:before {
    content: "\f111";
}
.icon-dropdown-arrows:before {
    content: "\f112";
}
.icon-funders:before {
    content: "\f113";
}
.icon-home:before {
    content: "\f114";
}
.icon-in-progress:before {
    content: "\f115";
}
.icon-info:before {
    content: "\f116";
}
.icon-interactive-costing:before {
    content: "\f117";
}
.icon-iras-submission:before {
    content: "\f118";
}
.icon-menu:before {
    content: "\f119";
}
.icon-modal-warning:before {
    content: "\f11a";
}
.icon-move-visit-arrows:before {
    content: "\f11b";
}
.icon-number-1:before {
    content: "\f11c";
}
.icon-number-2:before {
    content: "\f11d";
}
.icon-number-3:before {
    content: "\f11e";
}
.icon-on-hold:before {
    content: "\f11f";
}
.icon-paf-submissions:before {
    content: "\f120";
}
.icon-pending:before {
    content: "\f121";
}
.icon-rts:before {
    content: "\f122";
}
.icon-soecat-completed:before {
    content: "\f123";
}
.icon-studies-requiring-action:before {
    content: "\f124";
}
.icon-studies:before {
    content: "\f125";
}
.icon-submit-research-activity:before {
    content: "\f126";
}
.icon-success:before {
    content: "\f127";
}
.icon-tab-focus-arrow:before {
    content: "\f128";
}
.icon-toggle-on:before {
    content: "\f129";
}
.icon-toggle-off:before {
    content: "\f12a";
}
.icon-user-list:before {
    content: "\f12b";
}
.icon-warning:before {
    content: "\f12c";
}
