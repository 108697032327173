.continue-button {
  width: 110px;
  height: 47px;
  border-radius: 3px;
  background-color: #027f3a;
  color: #fff;
}
.cancel-button {
  width: 159px;
  height: 47px;
  margin: 3px 0 0;
  border-radius: 3px;
  background-color: #0b2241;
  color: #fff;
  float: right;
}

.bottom-card {
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
}
