.contentful-container {
  ol {
    margin-left: 20px;
    list-style-type: decimal;
    margin-bottom: 20px;

    li {
      padding-bottom: 0;
      margin-bottom: 20px;
    }

    li li {
      list-style-type:upper-alpha;
      padding-bottom: 0;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    li li li {
      list-style-type:lower-roman;
      padding-bottom: 0;
      margin-bottom: 10px;
    }
  }
  ul {
    margin-left: 20px;
    list-style-type: disc;
    margin-bottom: 20px;

    li {
      padding-bottom: 0;
      margin-bottom: 20px;
    }

    li li {
      list-style-type:circle;
      padding-bottom: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    li li li {
      list-style-type:square;
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }

  &--padding {
    p {
      &:not(:last-child) {
        padding-bottom: 10px;
      }

      &:not(:first-child) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    a {
      text-decoration: underline;
    }

    h1 {
      margin-bottom: 0;
      font-size: 24px;
    }
  }

  // Sometimes, contentful returns empty <p> tags. This removes the default spacing for those.
  p:empty {
    padding: 0;
  }
}
