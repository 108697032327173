// NIHR Colour Pallette
$color-cobalt: #193e72;
$color-blue: #193e72;
$color-dark-blue: #0B2241;
$color-light-blue: #2F65B0;
$color-gray: #6d7278;
$color-dark-gray: #191919;
$color-light-gray: #EDECEC;
$color-green: #027f3a;
$color-dark-green: #00411D;
$color-light-green: #45A96D;
$color-beige: #FFF9C4;
$color-dark-beige: #FED47A;
$color-turquoise: #2EAAB0;
$color-red: #E02020;
$color-gray-blue: #ACBCC3;
$color-purple: '#800080';
$color-orange: '#DF9901';

// NHS Colour Pallette - with NIHR overrides
$color_nhsuk-blue: $color-cobalt;
$color_nhsuk-white: #ffffff;
$color_nhsuk-black: $color-dark-gray;
$color_nhsuk-green: $color-green;
$color_nhsuk-purple: #330072;
$color_nhsuk-dark-pink: #7C2855;
$color_nhsuk-red: $color-red;
$color_nhsuk-yellow: #ffeb3b;

// Define the NIHR brand colours for use as CSS variables
:root {
  // Colours
  --cobalt: #193e72;
  --blue: #193e72;
  --dark-blue: #0B2241;
  --light-blue: #2F65B0;

  --gray: #6d7278;
  --dark-gray: #191919;
  --light-gray: #EDECEC;

  --green: #027f3a;
  --dark-green: #00411D;
  --light-green: #45A96D;

  --beige: #FFF9C4;
  --dark-beige: #FED47A;
  --turquoise: #2EAAB0;
  --red: #E02020;
  --gray-blue: #ACBCC3;
  --purple: #800080;
  --orange: #DF9901;
}

// Variable Overrides
$nhsuk-link-color: var(--gray);
$nhsuk-link-visited-color: $nhsuk-link-color;
$nhsuk-link-hover-color: $color-blue;

$nhsuk-button-color: $color-green;
$nhsuk-button-hover-color: tint($color-green, 10%);

$nhsuk-secondary-button-color: $color-blue;
$nhsuk-secondary-button-hover-color: tint($color-blue, 10%);
$nhsuk-secondary-button-active-color: $color-dark-blue;
$nhsuk-secondary-button-shadow-color: $color-dark-blue;

$nhsuk-reverse-button-color: $color_nhsuk-white;
