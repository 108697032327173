//TODO - Remove this file if nhsuk-frontend dependencies are upgraded to 6.0.1 and above.

.nhsuk-u-float-right {
  float: right;
}

$color_nhsuk-black: #212b32;
$color_nhsuk-white: #ffffff;
$color_nhsuk-blue: var(--cobalt, #005eb8);
$color_nhsuk-dark-pink: #7c2855;
$color_nhsuk-yellow: #ffeb3b;
$color_nhsuk-grey-1: #4c6272;
$color_nhsuk-grey-2: #768692;
$color_nhsuk-grey-3: #aeb7bd;
$color_nhsuk-grey-4: #d8dde0;
$color_nhsuk-grey-5: #f0f4f5;
$nhsuk-focus-width: 4px !default;
$nhsuk-focus-text-color: $color_nhsuk-black;
$nhsuk-link-hover-color: $color_nhsuk-dark-pink;
$nhsuk-focus-color: $color_nhsuk-yellow;
$nhsuk-text-color: $color_nhsuk-black;
$nhsuk-print-text-color: $color_nhsuk-black;
$nhsuk-spacing-points: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 40px,
  7: 48px,
  8: 56px,
  9: 64px,
) !default;
$mq-responsive: true;
$mq-media-type: all !default;
/// @type Map
/// @link https://github.com/sass-mq/sass-mq#seeing-the-currently-active-breakpoint Full documentation and examples
$mq-breakpoints: (
  mobile: 320px,
  tablet: 740px,
  desktop: 980px,
  wide: 1300px,
) !default;
$mq-static-breakpoint: desktop;
$static-breakpoint: $mq-static-breakpoint;
/// @ignore @param {Map} $breakpoints
/// @param {Map} $breakpoints
/// @param {String} $name
/// @example scss
///  $tablet-width: mq-get-breakpoint-width(tablet);
/// @requires {Variable} $mq-breakpoints
/// @returns {Number} Value in pixels

$nhsuk-spacing-responsive-scale: (
  0: (
    null: 0,
    tablet: 0,
  ),
  1: (
    null: 4px,
    tablet: 4px,
  ),
  2: (
    null: 8px,
    tablet: 8px,
  ),
  3: (
    null: 8px,
    tablet: 16px,
  ),
  4: (
    null: 16px,
    tablet: 24px,
  ),
  5: (
    null: 24px,
    tablet: 32px,
  ),
  6: (
    null: 32px,
    tablet: 40px,
  ),
  7: (
    null: 40px,
    tablet: 48px,
  ),
  8: (
    null: 48px,
    tablet: 56px,
  ),
  9: (
    null: 56px,
    tablet: 64px,
  ),
) !default;

$nhsuk-typography-scale: (
  64: (
    null: (
      font-size: 48px,
      line-height: 56px,
    ),
    tablet: (
      font-size: 64px,
      line-height: 72px,
    ),
    print: (
      font-size: 53pt,
      line-height: 1.1,
    ),
  ),
  48: (
    null: (
      font-size: 32px,
      line-height: 40px,
    ),
    tablet: (
      font-size: 48px,
      line-height: 56px,
    ),
    print: (
      font-size: 32pt,
      line-height: 1.15,
    ),
  ),
  32: (
    null: (
      font-size: 24px,
      line-height: 32px,
    ),
    tablet: (
      font-size: 32px,
      line-height: 40px,
    ),
    print: (
      font-size: 24pt,
      line-height: 1.05,
    ),
  ),
  24: (
    null: (
      font-size: 20px,
      line-height: 28px,
    ),
    tablet: (
      font-size: 24px,
      line-height: 32px,
    ),
    print: (
      font-size: 18pt,
      line-height: 1.15,
    ),
  ),
  22: (
    null: (
      font-size: 18px,
      line-height: 28px,
    ),
    tablet: (
      font-size: 22px,
      line-height: 32px,
    ),
    print: (
      font-size: 18pt,
      line-height: 1.15,
    ),
  ),
  19: (
    null: (
      font-size: 16px,
      line-height: 24px,
    ),
    tablet: (
      font-size: 19px,
      line-height: 28px,
    ),
    print: (
      font-size: 14pt,
      line-height: 1.15,
    ),
  ),
  16: (
    null: (
      font-size: 14px,
      line-height: 24px,
    ),
    tablet: (
      font-size: 16px,
      line-height: 24px,
    ),
    print: (
      font-size: 14pt,
      line-height: 1.2,
    ),
  ),
  14: (
    null: (
      font-size: 12px,
      line-height: 20px,
    ),
    tablet: (
      font-size: 14px,
      line-height: 24px,
    ),
    print: (
      font-size: 12pt,
      line-height: 1.2,
    ),
  ),
) !default;

@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  } @else {
    @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
  }
}
@function _nhsuk-line-height($line-height, $font-size) {
  @if not unitless($line-height) and unit($line-height) == unit($font-size) {
    $ten-to-the-power-five: 100000;
    $line-height: round(($line-height / $font-size) * $ten-to-the-power-five) / $ten-to-the-power-five;
  }

  @return $line-height;
}

@function _govuk-equilateral-height($base) {
  $square-root-of-three: 1.732;

  @return ($base / 2) * $square-root-of-three;
}

@mixin nhsuk-focused-text {
  background-color: $color_nhsuk-yellow;
  box-shadow: 0 -2px $color_nhsuk-yellow, 0 $nhsuk-focus-width $nhsuk-focus-text-color;
  color: $nhsuk-focus-text-color;
  outline: $nhsuk-focus-width solid transparent;
  text-decoration: none;
}

@mixin top-and-bottom() {
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

@mixin mq(
  $from: false,
  $until: false,
  $and: false,
  $media-type: $mq-media-type,
  $breakpoints: $mq-breakpoints,
  $responsive: $mq-responsive,
  $static-breakpoint: $mq-static-breakpoint
) {
  $min-width: 0;
  $max-width: 0;
  $media-query: '';

  @if $from {
    @if type-of($from) == number {
      $min-width: mq-px2em($from);
    } @else {
      $min-width: mq-px2em(mq-get-breakpoint-width($from, $breakpoints));
    }
  }

  @if $until {
    @if type-of($until) == number {
      $max-width: mq-px2em($until);
    } @else {
      $max-width: mq-px2em(mq-get-breakpoint-width($until, $mq-breakpoints)) - 0.01em;
    }
  }

  @if $responsive == false {
    $static-breakpoint-width: mq-get-breakpoint-width($static-breakpoint, $breakpoints);
    $target-width: mq-px2em($static-breakpoint-width);

    @if (
      $and ==
        false and
        $min-width <=
        $target-width and
        ($until == false or $max-width >= $target-width) and
        $media-type !=
        'print'
    ) {
      @content;
    }
  } @else {
    @if $min-width != 0 {
      $media-query: '#{$media-query} and (min-width: #{$min-width})';
    }
    @if $max-width != 0 {
      $media-query: '#{$media-query} and (max-width: #{$max-width})';
    }
    @if $and {
      $media-query: '#{$media-query} and #{$and}';
    }

    @if ($media-type == 'all' and $media-query != '') {
      $media-type: '';
      $media-query: str-slice(unquote($media-query), 6);
    }

    @media #{$media-type + $media-query} {
      @content;
    }
  }
}

@mixin _nhsuk-responsive-spacing(
  $responsive-spacing-point,
  $property,
  $direction: 'all',
  $important: false,
  $adjustment: false
) {
  $actual-input-type: type-of($responsive-spacing-point);
  @if $actual-input-type != 'number' {
    @error 'Expected a number (integer), but got a ' + '#{$actual-input-type}.';
  }

  @if not map-has-key($nhsuk-spacing-responsive-scale, $responsive-spacing-point) {
    @error 'Unknown spacing point `#{$responsive-spacing-point}`. Make sure you are using a point from the '
      + 'responsive spacing scale in `_settings/spacing.scss`.';
  }

  $scale-map: map-get($nhsuk-spacing-responsive-scale, $responsive-spacing-point);
  $actual-map-type: type-of($scale-map);
  @if $actual-map-type != 'map' {
    @error 'Expected a number (integer), but got a '
      + '#{$actual-map-type}. Make sure you are using a map to set the responsive spacing in `_settings/spacing.scss`)'; /* stylelint-disable-line indentation */
  }

  @each $breakpoint, $breakpoint-value in $scale-map {
    @if ($adjustment) {
      $breakpoint-value: $breakpoint-value + $adjustment;
    }

    @if $breakpoint == null {
      @if $direction == all {
        #{$property}: $breakpoint-value iff($important, !important);
      } @else {
        #{$property}-#{$direction}: $breakpoint-value iff($important, !important);
      }
    } @else {
      @include govuk-media-query($from: $breakpoint) {
        @if $direction == all {
          #{$property}: $breakpoint-value iff($important, !important);
        } @else {
          #{$property}-#{$direction}: $breakpoint-value iff($important, !important);
        }
      }
    }
  }
}

@mixin nhsuk-responsive-padding($responsive-spacing-point, $direction: 'all', $important: false, $adjustment: false) {
  @include _nhsuk-responsive-spacing($responsive-spacing-point, 'padding', $direction, $important, $adjustment);
}

@mixin govuk-media-query($args...) {
  @include mq($args...) {
    @content;
  }
}

@mixin nhsuk-responsive-margin($responsive-spacing-point, $direction: 'all', $important: false, $adjustment: false) {
  @include _nhsuk-responsive-spacing($responsive-spacing-point, 'margin', $direction, $important, $adjustment);
}

////// NOTE: Values assigned at the root element level are being assigned rather than
////// the parent element. The fix should be to change the below
////// 'inherit' values to 'initial' but this is not working.
////// Further investigation needed. I've disabled this and
////// manually recreated where needed

// @mixin govuk-shape-arrow($direction, $base, $height: null, $display: block) {
//   display: $display;

//   width: 0;
//   height: 0;

//   border-style: solid;
//   border-color: transparent;

//   $perpendicular: $base / 2;

//   @if ($height == null) {
//     $height: _govuk-equilateral-height($base);
//   }

//   @if $direction == 'up' {
//     clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

//     border-width: 0 $perpendicular $height $perpendicular;
//     border-bottom-color: inherit;
//   } @else if $direction == 'right' {
//     clip-path: polygon(0% 0%, 100% 50%, 0% 100%);

//     border-width: $perpendicular 0 $perpendicular $height;
//     border-left-color: inherit;
//   } @else if $direction == 'down' {
//     clip-path: polygon(0% 0%, 50% 100%, 100% 0%);

//     border-width: $height $perpendicular 0 $perpendicular;
//     border-top-color: inherit;
//   } @else if $direction == 'left' {
//     clip-path: polygon(0% 50%, 100% 100%, 100% 0%);

//     border-width: $perpendicular $height $perpendicular 0;
//     border-right-color: inherit;
//   } @else {
//     @error "Invalid arrow direction: expected `up`, `right`, `down` or `left`, got `#{$direction}`";
//   }
// }

@mixin nhsuk-text-color {
  color: $nhsuk-text-color;

  @include govuk-media-query($media-type: print) {
    color: $nhsuk-print-text-color;
  }
}

@mixin nhsuk-typography-responsive($size, $override-line-height: false, $important: false) {
  @if not map-has-key($nhsuk-typography-scale, $size) {
    @error 'Unknown font size `#{$size}` - expected a point from the typography scale.';
  }

  $font-map: map-get($nhsuk-typography-scale, $size);

  @each $breakpoint, $breakpoint-map in $font-map {
    $font-size: map-get($breakpoint-map, 'font-size');
    $font-size-rem: nhsuk-px-to-rem($font-size);

    $line-height: _nhsuk-line-height(
      $line-height: if($override-line-height, $override-line-height, map-get($breakpoint-map, 'line-height')),
      $font-size: $font-size,
    );

    $font-size: $font-size iff($important, !important);
    $font-size-rem: $font-size-rem iff($important, !important);
    $line-height: $line-height iff($important, !important);

    @if $breakpoint == null {
      font-size: $font-size;
      font-size: $font-size-rem;
      line-height: $line-height;
    } @else if $breakpoint == 'print' {
      @include govuk-media-query($media-type: print) {
        font-size: $font-size;
        line-height: $line-height;
      }
    } @else {
      @include govuk-media-query($from: $breakpoint) {
        font-size: $font-size;
        font-size: $font-size-rem;
        line-height: $line-height;
      }
    }
  }
}

@function nhsuk-spacing($spacing-point) {
  $actual-input-type: type-of($spacing-point);
  @if $actual-input-type != 'number' {
    @error 'Expected a number (integer), but got a '
      + '#{$actual-input-type}.';
  }

  @if not map-has-key($nhsuk-spacing-points, $spacing-point) {
    @error 'Unknown spacing variable `#{$spacing-point}`. Make sure you are using a point from the spacing scale in `_settings/spacing.scss`.';
  }

  @return map-get($nhsuk-spacing-points, $spacing-point);
}

.nhsuk-details {
  @include nhsuk-text-color;
  @include nhsuk-responsive-margin(4, 'bottom');
  @include nhsuk-typography-responsive(19);
  display: block;

  ///fix to work around root element inheritance.
  font-size: 1.5rem;
}

.nhsuk-details__summary {
  color: $color_nhsuk-blue; /* [1] */
  cursor: pointer;
  display: inline-block; /* [2] */
  padding-left: nhsuk-spacing(4);
  position: relative; /* [3] */

  &:hover {
    color: $nhsuk-link-hover-color;
  }

  &:before {
    bottom: 0;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    border-width: 7px 0 7px 12.124px;
    border-left-color: initial;

    //NOTE: disabled pending further investigation
    // @include govuk-shape-arrow($direction: right, $base: 22px);
  }

  &:focus {
    @include nhsuk-focused-text();

    .nhsuk-icon {
      fill: $nhsuk-focus-text-color;
    }
  }

  &:hover,
  &:focus {
    .nhsuk-details__summary-text {
      text-decoration: none;
    }
  }
}

.nhsuk-details[open] > .nhsuk-details__summary:before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border-width: 12.124px 7px 0 7px;
  border-top-color: initial;

  //NOTE: disabled pending further investigation
  //   @include govuk-shape-arrow($direction: down, $base: 14px);
}

.nhsuk-details__summary-text {
  text-decoration: underline;
}

.nhsuk-details__summary::-webkit-details-marker {
  display: none;
}

.nhsuk-details__summary::-webkit-details-marker {
  display: none;
}

.nhsuk-details__text {
  border-left: nhsuk-spacing(1) solid $color_nhsuk-grey-4;
  margin-top: nhsuk-spacing(2);
  padding: nhsuk-spacing(3);
  padding-left: 20px;
  @include top-and-bottom();
}

$expander-border-width: 1px;
$expander-border-bottom-width: nhsuk-spacing(1);
$expander-border-color: $color_nhsuk-grey-4;
$expander-border-hover-color: $color_nhsuk-grey-3;

.nhsuk-expander {
  background-color: $color_nhsuk-white;
  border: $expander-border-width solid $expander-border-color;
  border-bottom-width: $expander-border-bottom-width;

  &:hover {
    border-color: $expander-border-hover-color;
  }

  .nhsuk-details__summary {
    background-color: $color_nhsuk-white;
    border-top: $nhsuk-focus-width solid transparent;
    display: block;
    padding: nhsuk-spacing(4) - $nhsuk-focus-width nhsuk-spacing(4) nhsuk-spacing(4);

    @include mq($until: tablet) {
      padding: nhsuk-spacing(3) - $nhsuk-focus-width nhsuk-spacing(3) nhsuk-spacing(3);
    }

    &:before {
      display: none !important;
    }

    &:hover {
      .nhsuk-details__summary-text {
        color: $nhsuk-link-hover-color;
      }
    }

    &:focus {
      box-shadow: none;

      .nhsuk-details__summary-text {
        @include nhsuk-focused-text();

        &:before {
          background: $nhsuk-focus-color
            url("data:image/svg+xml,%3Csvg class='nhsuk-icon nhsuk-icon__plus' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' aria-hidden='true'%3E%3Ccircle cx='12' cy='12' r='10' fill='002f5c'%3E%3C/circle%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M12 8v8M8 12h8'%3E%3C/path%3E%3C/svg%3E%0A")
            left -2px center no-repeat;
        }
      }
    }
  }

  .nhsuk-details__summary-text {
    color: $color_nhsuk-blue;
    cursor: pointer;
    display: inline-block;
    padding: nhsuk-spacing(1) nhsuk-spacing(1) nhsuk-spacing(1) 38px;
    position: relative;

    &:before {
      background: url("data:image/svg+xml,%3Csvg class='nhsuk-icon nhsuk-icon__plus' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' aria-hidden='true'%3E%3Ccircle cx='12' cy='12' r='10' fill='%23005eb8'%3E%3C/circle%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M12 8v8M8 12h8'%3E%3C/path%3E%3C/svg%3E%0A")
        left -2px center no-repeat;
      content: '';
      display: inline-block;
      height: 32px;
      left: 0;
      position: absolute;
      top: calc(50% - 16px);
      width: 32px;
    }
  }

  .nhsuk-details__text {
    @include nhsuk-responsive-padding(4, 'bottom');
    @include nhsuk-responsive-padding(4, 'left');
    @include nhsuk-responsive-padding(4, 'right');
    @include nhsuk-responsive-padding(0, 'top');

    border-left: 0;
    margin-left: 0;
    margin-top: 0;
  }
}

.nhsuk-expander[open] {
  border-bottom-width: $expander-border-width;

  .nhsuk-details__summary {
    &:focus {
      &:hover {
        .nhsuk-details__summary-text {
          text-decoration: none;
        }
      }

      .nhsuk-details__summary-text::before {
        background: $nhsuk-focus-color
          url("data:image/svg+xml,%3Csvg class='nhsuk-icon nhsuk-icon__minus' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' aria-hidden='true'%3E%3Ccircle cx='12' cy='12' r='10' fill='002f5c'%3E%3C/circle%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M8 12h8'%3E%3C/path%3E%3C/svg%3E%0A")
          left -2px center no-repeat;
      }
    }
  }

  .nhsuk-details__summary-text::before {
    background: url("data:image/svg+xml,%3Csvg class='nhsuk-icon nhsuk-icon__minus' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' aria-hidden='true'%3E%3Ccircle cx='12' cy='12' r='10' fill='%23005eb8'%3E%3C/circle%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M8 12h8'%3E%3C/path%3E%3C/svg%3E%0A")
      left -2px center no-repeat;
  }
}

.nhsuk-expander-group {
  > .nhsuk-details {
    @include nhsuk-responsive-margin(2, 'bottom');
  }
  @include nhsuk-responsive-margin(4, 'bottom');
}

.nhsuk-details + %nhsuk-heading-l {
  @include nhsuk-responsive-padding(4, 'top');
}
