.nhsuk-textarea {
  padding-top: 5px;
  padding-bottom: 5px;

  &--no-resize {
    resize: none;
  }
}

.nhsuk-button {
  @include nhsuk-typography-responsive(16);
  font-weight: normal;

  // NHS library uses a `darken` function around the variable so we need to override it to prevent the darken
  &--secondary {
    &:hover {
      background-color: $nhsuk-secondary-button-hover-color;
    }
    &:active {
      background-color: $nhsuk-secondary-button-active-color;
    }
    &:focus:hover {
      background-color: $color_nhsuk-yellow;
      color: $color_nhsuk-black;
    }
  }

  &--icon {
    display: block;
    padding: 3px;
  }

  &--disabled {
    &.nhsuk-button--icon {
      background-color: transparent;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--link {
    &:focus {
      @include nhsuk-focused-button;
    }
  }

  &--reverse {
    &:visited {
      color: var(--cobalt);
    }
  }
}

.nhsuk-label.nhsuk-checkboxes__label{
  &--secondary {
    color: white;

    &::after {
      color: var(--cobalt);
    }
  }
}
