:root {
  --header-height: 65px;
}

.header {
  height: var(--header-height);

  .header-title {
    &:hover {
      h1 {
        text-decoration: none;
      }
    }
  }

  /* Increase specificity to override nhs styles */
  .header-nav-btn:focus, .header-nav-btn:focus svg {
    color: white;
    fill: white;
  }
}

.header-nav-btn {
  &:focus {
    outline: none;
    box-shadow: 0 2px 0px white, 0 -2px 0px white, 2px 0 0px white, -2px 0 0px white;

    svg {
      color: white;
    }
  }

  &:not(&--active) {
    transform: translateX(-0.75rem);
  }

  &--active,
  &:focus {
    background: rgba(255,255,255, 0.15);
  }
}
