.err {
  color: #e02020;
}
.error-message {
  min-height: 85px;
  margin: 10px 0;
  padding: 10px;
  background-color: #fff9c4;
  display: flex;
  align-items: flex-start;
}
.error-icon {
  width: 60px;
  height: 60px;
  margin: 5px 10px;
  text-align: center;
  display: flex;
  justify-content: start;
}
.error-content {
  margin: 5px;
}
.error-title {
  color: #e02020;
  font-size: 20px;
}
.error-list {
  margin-top: 10px;
  list-style-type: none;

  .quotes {
    quotes: "“" "”" "‘" "’";

    &::before {
      content: open-quote;
    }
    &::after {
      content: close-quote;
    }
  }
}

.error-list>li::before {
  content: "";
  display: inline-block;
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
  background-color: #191919;
}
